<template>
    <section class="subheroes">
        <div class="subheroes__wrapper wrapper">
            <article class="subhero subhero--primary" @click="goToPath('/mesteredzok')">
                <div class="subhero__visual">
                    <img class="subhero__image" src="../../images/_content/subheroes/coaches-stk-202309.jpg" alt="">
                </div>
                <h2 class="subhero__title">Mester-<br>edzők</h2>
            </article>
            <article class="subhero subhero--secondary" @click="goToPerson('okolvivas-1926-papp-laszlo')"><!-- this.$router.push({ name: 'greetings' }) -->
                <div class="subhero__visual">
                    <!-- <img class="subhero__image" src="../../images/_content/subheroes/schmitt-pal-202302.jpg" alt=""> -->

                    <video class="subhero__video" autoplay loop muted playsinline ref="heroVideo">
                        <source src="../../videos/_content/subheroes/papp-1.mov" type="video/mp4">
                    </video>
                    <!-- <div class="subhero__with-video">Videó</div> -->
                </div>
                <h2 class="subhero__title">Papp László az első ökölvívó a világon, aki egymás után háromszor nyert olimpiát</h2>
            </article>
        </div>
    </section>
</template>

<script>
export default {
    methods: {
        goToPerson(personPath) {
            this.$router.push({ name: 'person', params: { personPath: personPath } })
        },

        goToPath(path) {
            this.$router.push(path)
        }
    },

    mounted() {
        // Only for demo
        const heroVideo = this.$refs.heroVideo
        if (heroVideo) {
            heroVideo.playbackRate = 0.8;
        }
    }

}
</script>
