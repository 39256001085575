<template>
    <article class="person" :class="personClasses" @click="openPerson(person.path)">
        <div class="person__portrait" :style="`background-image: url('${person.images.profile_pic.urls.thumb_lg}');`" v-if="person.images.profile_pic"></div>
        <header class="person__header">
            <time class="person__years" v-if="!masterCoach && !footballPlayer && !athleteOfTheNation">
                {{person.year_of_birth}}
                <span v-if="person.year_of_death"> &mdash; {{person.year_of_death}}</span>
            </time>
            <h2 class="person__name">{{person.name}}</h2>
            <div class="person__meta">
                <div class="person__para" v-if="person.para">Para</div>
                <div class="person__sport">
                    <span v-if="!footballPlayer && !olympics">{{person.sport_names.join(", ")}}</span>
                    <span v-if="footballPlayer">
                        <!-- <template v-if="featuredData.captain">Csapatkapitány / </template> -->
                        {{ featuredData.position }}
                    </span>
                    <span v-if="olympics">
                        {{ featuredData.info }}
                    </span>
                </div>
            </div>
        </header>
        <div class="person__drawer"></div>

        <!-- <div class="person__super" v-if="showSuper">
        </div> -->
        <div class="person__master-coach-label" v-if="masterCoach"><span class="person__master-coach-label__year">{{ person.master_coach_role_meta.master_coach_year }}</span></div>

        <div class="person__football-no" v-if="footballPlayer && featuredData.no">{{ featuredData.no }}</div>
        <div class="person__football-captain" v-if="footballPlayer && featuredData.captain">C</div>

        <div class="person__aotn-label" v-if="athleteOfTheNation"><span class="person__aotn-label__year">{{ featuredData.membership_year_start }}</span></div>
    </article>
</template>

<script>
export default {
    props: {
        person: {
            type: Object,
            required: true
        },

        openBlank: {
            type: Boolean,
            default: false
        },

        masterCoach: {
            type: Boolean,
            default: false
        },

        footballPlayer: {
            type: Boolean,
            default: false
        },

        olympics: {
            type: Boolean,
            default: false
        },

        athleteOfTheNation: {
            type: Boolean,
            default: false
        },

        featuredContext: {
            type: String,
            required: false
        },

        featuredData: {
            type: Object,
            required: false
        }
    },

    computed: {
        personClasses() {
            let classes = []

            if (this.person.champion_level) {
                classes.push("person--champion-level-" + this.person.champion_level.replace("_", "-"))
            }

            if (this.masterCoach) {
                classes.push('person--master-coach')
            }

            if (this.featuredContext) {
                classes.push('person--featured-' + this.featuredContext)
            }

            if (this.footballPlayer) {
                classes.push('person--football-player')
            }

            if (this.olympics) {
                classes.push('person--olympics')
            }

            if (this.athleteOfTheNation) {
                classes.push('person--aotn')
            }

            return classes
        },

        // showSuper() {
        //     let showSuper = false
        //     if (this.masterCoach) {
        //         showSuper = true
        //     }
        //     return showSuper
        // }
    },

    methods: {
        openPerson(personPath) {
            if (this.openBlank) {
                let route = this.$router.resolve({ name: 'person', params: { personPath: personPath } })
                window.open(route.href, '_blank')
            } else {
                this.$router.push({ name: 'person', params: { personPath: personPath } })
            }
        }
    }
}
</script>
