<script setup>
import { computed, onMounted, ref } from 'vue'
import ApiUrl from '../../api_urls.js'
import PersonCard from 'js/people/person-card.vue'
import axios from "axios"

axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector("meta[name=csrf-token]").content

const COLLATOR = new Intl.Collator('hu')

const isLoading = ref(true)
const featureds = ref([])

const enabledFeatureds = computed(() => featureds.value.filter(f => f.featured_data.enabled))
const people = computed(() => enabledFeatureds.value
    .sort((a, b) => COLLATOR.compare(a.person.name, b.person.name))
    .sort((a, b) => b.featured_data.membership_year_start - a.featured_data.membership_year_start)
)

function getFeatureds() {
    axios.get(ApiUrl('featured_people', null, 'context=athlete_of_the_nation'))
        .then(response => {
            featureds.value = response.data
            isLoading.value = false
        })
        .catch(error => {
        })
}

onMounted(() => {
    getFeatureds()
})
</script>



<template>
<Header variant="t/w" logo-variant="horizontal-centered-white" hoist />
<main class="aotn-page">
    <div class="wrapper">
        <header class="aotn-header">
            <h1 class="aotn-header__title">A Nemzet<br>Sportolói</h1>
        </header>

        <section class="people-list" v-if="!isLoading">
            <PersonCard athlete-of-the-nation open-blank :person="p.person" :featured-context="p.context" :featured-data="p.featured_data" :key="p.person.id" v-for="p in people" />
        </section>

        <section class="aotn-description">
            <p class="aotn-description__text">A sportról szóló 2004. évi I. törvény 62. §-a alapján a Kormány a Nemzet Sportolóinak javaslata alapján a <strong>Nemzet Sportolója</strong> címet és az ezzel járó életjáradékot biztosíthat a magyar sport tizenkét, hatvanadik életévét betöltött, kimagasló eredményt elért sportolónak, aki aktív sportpályafutását követően is fontos szerepet töltött be a magyar sportéletben.</p>
        </section>
    </div>
</main>
<Footer />
<Loader :active="isLoading" fullscreen />
</template>
