<template>
    <Header variant="t/w" logo-variant="horizontal-centered-white" hoist />
    <section class="hero" v-if="person">
        <div class="hero__visual-container">

            <div class="hero__image" :style="`background-image: url('${person.images.hero.urls.large}');`" v-if="!hasHeroVideo && person.images.hero"></div>

            <!--<video class="hero__video" autoplay loop muted playsinline ref="heroVideo" v-if="hasHeroVideo">
                <source src="../../videos/_demo/dana2.mov" type="video/mp4">
            </video>-->

            <div class="hero__crown"></div>
            <div class="hero__overlay"></div>
            <div class="hero__visual-container__wrapper wrapper wrapper--narrow">
                <time class="hero__years">{{person.bio.year_of_birth}}<span v-if="person.bio.year_of_death">&mdash;{{person.bio.year_of_death}}</span></time>
            </div>
        </div>
        <header class="hero__wrapper wrapper wrapper--narrow">
            <div class="hero__content-container">
                <div class="hero__portrait">
                    <div class="hero__portrait__image" :style="`background-image: url('${person.images.profile_pic.urls.thumb_lg}');`" v-if="person.images.profile_pic"></div>
                </div>
                <div class="hero__content">
                    <div class="hero__labels">
                        <a class="hero__label hero__label--featured hero__label--olympics" :href="`/#/paris2024/${Paris2024SportSlug}`" target="_blank" v-if="isParis2024">Paris 2024</a>
                        <a class="hero__label hero__label--featured hero__label--football" href="/#/euro2024" target="_blank" v-if="isEuro2024">Euro 2024</a>
                        <a class="hero__label hero__label--featured hero__label--aotn" href="/#/a-nemzet-sportoloi" target="_blank" v-if="isAotn">A Nemzet Sportolója</a>
                        <div class="hero__label hero__label--sports" v-for="sportName in person.sport_names">{{ sportName }}</div>
                        <div class="hero__label hero__label--para" v-if="person.person_data.para">Parasportoló</div>
                        <div class="hero__label hero__label--coach" v-if="isMasterCoach">Mesteredző</div>
                    </div>
                    <h1 class="hero__name">
                        <div class="hero__name__last-name">{{person.name.last_name}}</div>
                        <div class="hero__name__first-name">{{person.name.first_name}} <span v-if="person.name.middle_name">{{person.name.middle_name}}</span></div>
                    </h1>
                </div>
            </div>
        </header>
    </section>

    <section class="lead wrapper wrapper--narrow" v-if="person">
        <p class="lead__text">{{person.person_data.lead}}</p>
    </section>
    <!--{{person.best_results}}-->

    <div class="wrapper wrapper--narrow" v-if="person">
        <section class="basic-data-n-medals">
            <section class="basic-data">
                <div class="basic-data__row">
                    <div class="basic-data__item">
                        <div class="basic-data__label">Születési hely</div>
                        <div class="basic-data__value">{{person.bio.place_of_birth}}</div>
                    </div>
                    <div class="basic-data__item">
                        <div class="basic-data__label">Születési idő</div>
                        <div class="basic-data__value">{{niceDateOfBirth}}</div>
                    </div>
                    <div class="basic-data__item" v-if="person.bio.year_of_death">
                        <div class="basic-data__label">Halálának ideje</div>
                        <div class="basic-data__value">{{niceDateOfDeath}}</div>
                    </div>
                </div>
                <div class="basic-data__row">
                    <div class="basic-data__item" v-if="person.bio.place_of_death">
                        <div class="basic-data__label">Halálának helye</div>
                        <div class="basic-data__value">{{person.bio.place_of_death}}</div>
                    </div>
                    <div class="basic-data__item" v-if="person.name.nickname">
                        <div class="basic-data__label">Becenév</div>
                        <div class="basic-data__value">{{person.name.nickname}}</div>
                    </div>
                    <!--<div class="basic-data__item">
                        <div class="basic-data__label">Állampolgárság</div>
                        <div class="basic-data__value">{{person.nationality}}</div>
                    </div>-->
                </div>
            </section>
            <section class="medals">
                <div class="medals__column">
                    <div class="medals__competition-type">
                        <span v-if="person.para">Paralimpia</span>
                        <span v-else>Olimpia</span>
                    </div>
                    <div class="medals__container" v-if="Object.keys(person.filterable_data.medals.olympics).length > 0">
                        <div class="medals__medal medal medal--with-counter medal--gold" v-if="person.filterable_data.medals.olympics[1]">{{person.filterable_data.medals.olympics[1]}}</div>
                        <div class="medals__medal medal medal--with-counter medal--silver" v-if="person.filterable_data.medals.olympics[2]">{{person.filterable_data.medals.olympics[2]}}</div>
                        <div class="medals__medal medal medal--with-counter medal--bronze" v-if="person.filterable_data.medals.olympics[3]">{{person.filterable_data.medals.olympics[3]}}</div>
                    </div>
                    <div class="medals__no-data" v-else>
                        &mdash;
                    </div>
                </div>
                <div class="medals__column">
                    <div class="medals__competition-type">Világ&shy;bajnokság</div>
                    <div class="medals__container" v-if="Object.keys(person.filterable_data.medals.wc).length > 0">
                        <div class="medals__medal medal medal--with-counter medal--gold" v-if="person.filterable_data.medals.wc[1]">{{person.filterable_data.medals.wc[1]}}</div>
                        <div class="medals__medal medal medal--with-counter medal--silver" v-if="person.filterable_data.medals.wc[2]">{{person.filterable_data.medals.wc[2]}}</div>
                        <div class="medals__medal medal medal--with-counter medal--bronze" v-if="person.filterable_data.medals.wc[3]">{{person.filterable_data.medals.wc[3]}}</div>
                    </div>
                    <div class="medals__no-data" v-else>
                        &mdash;
                    </div>
                </div>
                <div class="medals__column">
                    <div class="medals__competition-type">Európa-bajnokság</div>
                    <div class="medals__container" v-if="Object.keys(person.filterable_data.medals.ec).length > 0">
                        <div class="medals__medal medal medal--with-counter medal--gold" v-if="person.filterable_data.medals.ec[1]">{{person.filterable_data.medals.ec[1]}}</div>
                        <div class="medals__medal medal medal--with-counter medal--silver" v-if="person.filterable_data.medals.ec[2]">{{person.filterable_data.medals.ec[2]}}</div>
                        <div class="medals__medal medal medal--with-counter medal--bronze" v-if="person.filterable_data.medals.ec[3]">{{person.filterable_data.medals.ec[3]}}</div>
                    </div>
                    <div class="medals__no-data" v-else>
                        &mdash;
                    </div>
                </div>
            </section>
        </section>
    </div>

    <article class="bio-blocks wrapper wrapper--narrow" v-if="person">
        <template :key="block.id" v-for="block in person.bio_blocks">
            <div class="bio-block" v-if="!block.is_quote">
                <div class="bio-block__title-container">
                    <h3 class="bio-block__title" v-if="block.title">{{formatBioTitle(block.title)}}</h3>
                    <h4 class="bio-block__subtitle" v-if="block.subtitle">{{block.subtitle}}</h4>
                </div>
                <div class="bio-block__images" v-if="block.images.length > 0">
                    <a :href="image.urls.medium" class="bio-block__image" data-fancybox="bio-images" :data-caption="getCaption(image)" v-for="image in block.images">
                        <img class="bio-block__image__image" :src="image.urls.small" alt="">
                    </a>
                </div>
                <div class="bio-block__content" v-html="block.content" v-if="block.content"></div>
            </div>
            <blockquote class="bio-quote" v-if="block.is_quote">
                <p class="bio-quote__text">{{block.content}}</p>
                <div class="bio-quote__dqu">&#8220;</div>
                <div class="bio-quote__dql">&#8222;</div>
            </blockquote>
        </template>


        <div class="bio-block" v-if="false && person.best_results">
            <div class="bio-block__title-container">
                <h3 class="bio-block__title">Legjobb eredményei</h3>
            </div>
            <div class="bio-block__content" v-html="person.best_results"></div>
        </div>
    </article>


    <section class="clubs wrapper" v-if="person">
        <h2 class="section-title section-title--right">Egyesületei</h2>
        <div class="clubs__container">
            <table>
                <tr v-for="club in person.clubs">
                    <td class="yrs">
                        {{club.year_start}} &mdash; {{club.year_end}}
                    </td>
                    <td>
                        <div class="club-name">{{club.club.name}}</div>
                        <div class="club-city">{{club.club.city}}</div>
                    </td>
                </tr>
            </table>
        </div>
    </section>


    <Results :person="person" />


    <section class="gallery" v-if="person && person.images.gallery.length > 0">
        <div class="wrapper">
            <h2 class="gallery__title section-title section-title--right">Galéria</h2>
            <div class="gallery__images">
                <a class="gallery__image" :href="image.urls.large" data-fancybox="gallery" :data-caption="getCaption(image)" :style="`background-image: url('${image.urls.thumb_sm}');`" v-for="image in person.images.gallery">
                    <!-- <img class="gallery__image__image" :src="image.urls.thumb_sm"> -->
                    <!-- <div class="gallery__image__info">
                        <h4 class="gallery__image__title">{{image.title}}</h4>
                        <p class="gallery__image__description">{{image.description}}</p>
                    </div> -->
                </a>
            </div>
        </div>
    </section>


    <Footer />
</template>

<script>
import { useMainStore } from '../stores/main'
import niceDatesMixin from 'js/mixins/nice_dates_mixin'
import Results from './results/results.vue'
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

Fancybox.defaults.Hash = false
Fancybox.bind("[data-fancybox]", {
    Images : {
        protected: true
    }
});

// Only for demo
const HERO_VIDEO_ENABLED_FOR = ['4a480722-6c45-40c8-a975-0689b00bfa3d']

export default {
    mixins: [niceDatesMixin],

    props: {
        personPath: {
            type: String,
            required: true
        }
    },

    setup() {
        const store = useMainStore()
        return {
            store
        }
    },

    components: {
        Results
    },

    data() {
        return {
            isMasterCoach: false,
            //masterCoachYear: null
        }
    },

    computed: {
        person() {
            return this.store.persons[this.personPath]
        },

        niceDateOfBirth() {
            return this.getNiceDate(this.person.bio.display_date_of_birth, true)
        },

        niceDateOfDeath() {
            return this.getNiceDate(this.person.bio.display_date_of_death, true)
        },

        // Only for demo
        hasHeroVideo() {
            if (this.person && HERO_VIDEO_ENABLED_FOR.includes(this.id)) {
                return true
            }
            return false
        },

        hasFeatured() {
            return this.person.featured.length > 0
        },

        isEuro2024() {
            if (this.hasFeatured) {
                return this.person.featured.find(f => f.context == 'euro2024')
            }
        },

        isParis2024() {
            if (this.hasFeatured) {
                return this.person.featured.find(f => f.context == 'paris2024')
            }
        },

        Paris2024SportSlug() {
            if (this.isParis2024) {
                return this.person.featured.find(f => f.context == 'paris2024')?.sport.slug
            }
        },

        isAotn() {
            if (this.hasFeatured) {
                return this.person.featured.find(f => f.context == 'athlete_of_the_nation')
            }
        },
    },

    methods: {
        // TODO: do this on the backend
        formatBioTitle(title) {
            return title.replace('"', '').replace('„', '').replace('”', '')
        },

        hasBioBlockImages(block) {
            return block.images.length > 0
        },

        getCaption(image) {
            let caption = null
            if ('unknown_photographer' in image && image.unknown_photographer) {
                caption = "Fotó: ismeretlen szerző"
            } else if ('photographer' in image && image.photographer) {
                caption = `Fotó: ${image.photographer}`
            }
            return caption
        },

        initMasterCoach() {
            if (!this.person) {
                return false
            }
            for (let role of this.person.roles) {
                if (role.role == 'coach' && role.meta && role.meta.coach && role.meta.coach.master_coach) {
                    this.isMasterCoach = true
                    //this.masterCoachYear = role.meta.coach.master_coach_year || null
                    break
                }
            }
        }
    },

    mounted() {
        this.store.getPerson(this.personPath).then(() => {
            this.initMasterCoach()
        })
        document.body.classList.add('single-person')

        // Only for demo
        if (this.hasHeroVideo) {
            let heroVideo = this.$refs.heroVideo
            if (heroVideo) {
                heroVideo.playbackRate = 0.4;
            }
        }
    },

    unmounted() {
        document.body.classList.remove('single-person')
    }
}
</script>
